export default [
  {
    title: 'NO',
    slots: { customRender: 'no' },
  },
  {
    title: 'ID MDXL',
    dataIndex: 'id',
  },
  {
    title: 'Row ID',
    dataIndex: 'row_id',
  },
  {
    title: 'Table Name',
    dataIndex: 'table_name',
  },
  {
    title: 'Time',
    dataIndex: 'time',
  },
  {
    title: 'Model',
    dataIndex: 'model',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Request',
    dataIndex: 'request',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Response',
    dataIndex: 'response',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Status',
    // dataIndex: 'status',
    slots: { customRender: 'status' },
  },
  {
    title: 'Message',
    dataIndex: 'message',
  },
  {
    title: 'Http Status',
    dataIndex: 'http_status',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
