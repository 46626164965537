<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Fungsi"
    allow-clear
    :not-found-content="loading ? undefined : 'Tidak ditemukan'"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @blur="blur"
    @select="select"
    @deselect="deselect"
    @dropdownVisibleChange="dropdownVisibleChange"
    :loading="loading"
    v-bind="$attrs"
    :show-arrow="true"
  >
    <a-select-option v-for="d in dataRegional" :key="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
    <template v-if="loading" #notFoundContent>
      <a-spin size="small" />
    </template>
  </a-select>
</template>

<script>
import { onMounted, ref } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce } from 'lodash'
export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
    mode: {
      type: String,
      default: 'multiple',
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const dataRegional = ref([])
    const findText = ref(null)
    const loading = ref(false)

    const fetchDataRegional = (q = null) => {
      loading.value = true
      apiClient
        .get('/api/filter/synchron-function', {
          params: { q },
        })
        .then(response => {
          if (response && response.data) {
            const values = response.data.map(v => {
              return { id: v.function, name: v.function }
            })
            console.log(values)
            const sorted = values
            dataRegional.value = values
            // dataRegional.value = sorted.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
          } else {
            dataRegional.value = []
          }
        })
        .finally(() => {
          loading.value = false
        })
    }
    onMounted(() => {
      fetchDataRegional()
    })

    const onSearch = debounce(value => {
      loading.value = true
      console.log({ value, findText: findText.value })
      findText.value = value
      fetchDataRegional(value)
    }, 300)

    const highlight = value => {
      if (value === undefined || value === null) return value
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    const deselect = value => {
      console.log({ deselect: '', value })
    }
    const select = value => {
      if (findText.value !== null) {
        fetchDataRegional()
        findText.value = null
      }
    }
    const blur = value => {
      console.log({ blur: '', value })
    }
    const dropdownVisibleChange = value => {
      console.log({ dropdownVisibleChange: '', value })
      if (findText.value !== null) {
        fetchDataRegional()
        findText.value = null
      }
    }

    return {
      dataRegional,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      loading,
      deselect,
      blur,
      dropdownVisibleChange,
      select,
    }
  },
}
</script>

<style></style>
